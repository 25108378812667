<template>
  <div class="chips">
    <!-- {{chips}} -->
    <div class="chips__item" v-for="chip in chips" :key="chip.type">
      <div
        v-if="
          chip.type === 'search' ||
          chip.type === 'category' ||
          chip.type === 'q'
        "
        class="chips__item__text"
      >
        {{ chip.value }}
      </div>
      <div v-else-if="chip.type === 'country'" class="chips__item__text">
        <span class="chips__item__text--lessImportant">Ship from: </span
        >{{ chip.value }}
      </div>
      <div v-else-if="chip.type === 'tag'" class="chips__item__text">
        <span class="chips__item__text--lessImportant">Attributes: </span
        >{{ chip.value }}
      </div>
      <div v-else-if="chip.type === 'shipping_time'" class="chips__item__text">
        <span class="chips__item__text--lessImportant">Shipping time: </span
        >{{ chip.value }}
      </div>
      <div v-else-if="chip.type === 'sort'" class="chips__item__text">
        <span class="chips__item__text--lessImportant">Sort by: </span
        >{{ chip.value }}
      </div>
      <span
        class="material-symbols-outlined chips__item__icon"
        @click="$emit('removeChips', chip)"
      >
        close
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    chips: { type: Array, required: true },
  },
  data() {
    return {
      sortBy: "",
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.chips {
  display: flex;
  align-items: center;
  &__item {
    display: flex;
    align-items: center;
    background-color: #e4e7ec;
    border-radius: 4px;
    padding: 2px 6px;
    margin-right: 10px;
    &__text {
      margin-right: 20px;
      padding: 0;
      font-weight: 600;
      color: black;

      &--lessImportant {
        font-weight: lighter;
      }
    }
    &__icon {
      font-weight: 600;
      color: black;
      font-size: 1.7rem;
      cursor: pointer;
    }
  }
}
</style>
