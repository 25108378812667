<template>
  <div class="product-card-table">
    <table style="overflow-x: auto" class="Polaris-DataTable__Table">
      <thead style="background: #fafbfb">
        <tr>
          <th
            class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignMiddle Polaris-DataTable__Cell--firstColumn Polaris-DataTable__Cell--header"
            style="width: 50px"
          >
            <label class="custom-checkbox-2">
              <input
                type="checkbox"
                @change="selectAllProducts"
                v-model="selectAll"
              />
              <span class="checkmark-2" style="top: -9px !important"></span>
            </label>
          </th>
          <th
            class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignMiddle Polaris-DataTable__Cell--firstColumn Polaris-DataTable__Cell--header"
            v-for="(head, headIndex) in tableHeads"
            :key="headIndex"
          >
            {{ head }}
          </th>
          <th
            class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignMiddle Polaris-DataTable__Cell--firstColumn Polaris-DataTable__Cell--header"
            style="text-align: center"
          >
            Actions
          </th>
        </tr>
      </thead>

      <tbody class="body">
        <tr
          class="Polaris-DataTable__TableRow Polaris-DataTable--hoverable each-row"
          v-for="product in products"
          :key="product.id"
        >
          <th
            class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignMiddle Polaris-DataTable__Cell--firstColumn"
            scope="row"
          >
            <label class="custom-checkbox-2">
              <input
                ref="productCheckBox"
                type="checkbox"
                :value="product"
                @change="selectProductFunction(product)"
              />
              <span class="checkmark-2" style="top: -9px !important"></span>
            </label>
          </th>

          <td
            class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignMiddle Polaris-DataTable__Cell--firstColumn"
          >
            <div class="product-title-row">
              <div class="image-container">
                <img
                  :src="
                    product.productImages[0].thumbnailImage
                      ? product.productImages[0].thumbnailImage
                      : ''
                  "
                  :alt="product.title"
                  loading="lazy"
                />
              </div>
              <a :href="product.externalUrl">
                <h2 class="product-title">
                  {{ product.title }}
                </h2>
              </a>
            </div>
          </td>

          <td
            class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignMiddle Polaris-DataTable__Cell--firstColumn"
          >
            <span v-if="product.stock > 5" class="text-span">
              {{ product.stock }} in stock
            </span>
            <span
              v-if="product.stock >= 1 && product.stock <= 5"
              class="stock_alert"
            >
              {{ product.stock }}
              Low stock
            </span>
            <span v-else-if="product.stock == 0" class="stock_low">
              {{ product.stock }}
              Out of stock
            </span>
          </td>

          <td
            class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignMiddle Polaris-DataTable__Cell--firstColumn"
          >
            <div v-if="product.minPrice == product.maxPrice">
              <span class="text-span">
                {{ toCurrency(product.minPrice, product.currency) }}
              </span>
            </div>
            <div v-else>
              <span
                class="text-span"
                style="display: inline-block; margin-right: 5px"
              >
                From:
                {{ toCurrency(product.minPrice, product.currency) }}
              </span>
              <span class="text-span">
                To: {{ toCurrency(product.maxPrice, product.currency) }}
              </span>
            </div>
          </td>
          <td
            class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignMiddle Polaris-DataTable__Cell--firstColumn"
          >
            <div>
              <span class="text-span">
                {{ toCurrency(product.minPrice, product.currency) }}
              </span>
            </div>
          </td>
          <td
            class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignMiddle Polaris-DataTable__Cell--firstColumn"
          >
            <div class="my-product-page-actions">
              <a
                :href="product.externalUrl"
                class="view mr-3 tableEdtiBtn"
                target="_blank"
              >
                <img
                  width="17px"
                  height="17px"
                  loading="lazy"
                  class="icon-color"
                  src="@/assets/img/view.png"
                />
                <span class="tooltiptext pl-2">Edit on your store</span>
              </a>
              <div
                @click="backToImportList(product.id)"
                class="view tableEdtiBtn mr-3"
                target="_blank"
              >
                <img
                  width="17px"
                  height="17px"
                  loading="lazy"
                  class="icon-color"
                  src="@/assets/img/back.png"
                />
                <span class="tooltiptext">Back to import list</span>
              </div>
              <div
                @click.stop="deleteFromImportList(product.id)"
                class="delete tableEdtiBtn"
                target="_blank"
              >
                <img
                  width="17px"
                  height="17px"
                  loading="lazy"
                  src="@/assets/img/trash.png"
                />
                <span class="tooltiptext">Delete from shop</span>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div v-if="!allowToPushAll" class="bulk-container">
      <button
        class="vgang_btn"
        @click="backAllImportList()"
        style="margin-right: 20px; color: #fff; border-radius: 8px"
      >
        Back to import list
      </button>

      <button
        class="vgang_btn"
        @click="deleteAllImportList()"
        style="color: #fff; border-radius: 8px"
      >
        Remove from store
      </button>
    </div>
  </div>
</template>

<script>
// Services

import RetailerProducts from "@/services/RetailerProducts";
import { mapGetters, mapActions } from "vuex";
export default {
  props: {
    tableHeads: {
      type: Array,
      required: true,
    },
    products: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      removingProduct: false,
      backingImportlist: false,
      selectedProducts: [], // Array to store selected products
      selectAll: false, // Add this property for the "Select All" checkbox
    };
  },
  filters: {
    toCurrency: function (value) {
      if (typeof value !== "number") {
        return value;
      }
      var formatter = new Intl.NumberFormat("en-EU", {
        style: "currency",
        currency: this.defaultCurrency,
        minimumFractionDigits: 2,
      });
      return formatter.format(value);
    },
  },
  computed: {
    ...mapGetters({
      newAlert: "alert/newAlert",
      alertError: "alert/alertError",
    }),
    allowToPushAll() {
      return this.selectedProducts.length > 0 ? false : true;
    },
  },
  methods: {
    ...mapActions(["alert/clear", "alert/newAlert"]),
    selectProductFunction(product) {
      const found = this.selectedProducts.find(
        (selectedProduct) => product.id === selectedProduct.id
      );

      found
        ? this.selectedProducts.splice(this.selectedProducts.indexOf(found), 1)
        : this.selectedProducts.push(product);
    },

    checkAllProduct(checked) {
      const allCheckBox = this.$refs.productCheckBox;
      allCheckBox.forEach((item) => (item.checked = checked));
    },
    selectAllProducts() {
      if (this.selectAll) {
        this.selectedProducts = [...this.products];
        this.checkAllProduct(true);
      } else {
        // If the "Select All" checkbox is unchecked, clear the selectedProducts array
        this.selectedProducts = [];
        this.checkAllProduct(false);
      }

      this.selected = this.selectAll
        ? this.products.map((product) => product.id)
        : [];
    },

    async backAllImportList() {
      const agreed = window.confirm(
        "Are you sure you want to go back to import list?"
      );
      if (agreed) {
        this.selectedProducts.forEach(async (product) => {
          await this.backToImportListListForAll(product.id);
        });
        this.selectedProducts = [];
        this.selectAll = false;
        this.$emit("force-render");
      }
    },
    backToImportListListForAll(productID) {
      //this.removingProduct = false;

      RetailerProducts.backToImportList(productID)
        .then((response) => {
          // hcheck the reponse here before doing this next lines
          if (response.status === 200) this.$emit("removeProduct", productID);

          this.backingImportlist = false;
          this["alert/newAlert"]({
            type: "success",
            message: "Product backed to import list successfully",
          });
          setTimeout(async () => {
            this.showAlertError = false;
            this["alert/clear"]();
          }, 3000);
        })
        .catch((e) => {
          this["alert/newAlert"]({
            type: "error",
            message: e.data.message,
          });
          setTimeout(async () => {
            this.showAlertError = false;
            this["alert/clear"]();
          }, 3000);
        });
    },
    async deleteAllImportList() {
      const deleteProduct = window.confirm(
        "Are you sure you want to delete all product?"
      );
      if (deleteProduct) {
        this.selectedProducts.forEach((product) => {
          this.deleteFromImportListForAll(product.id);
        });
        this.selectedProducts = [];
        this.selectAll = false;
        this.$emit("force-render");
      }
    },

    deleteFromImportListForAll(productID) {
      RetailerProducts.deleteProductToImportList(productID)
        .then((response) => {
          if (response.status === 200) this.$emit("removeProduct", productID);
          this["alert/newAlert"]({
            type: "success",
            message: "Product Deleted successfully",
          });
          setTimeout(async () => {
            this.showAlertError = false;
            this["alert/clear"]();
          }, 3000);
        })
        .catch((e) => {
          this["alert/newAlert"]({
            type: "error",
            message: e.data.message,
          });
          setTimeout(async () => {
            this.showAlertError = false;
            this["alert/clear"]();
          }, 3000);

          console.log(e);
        });
    },
    deleteFromImportList(productID) {
      RetailerProducts.deleteProductToImportList(productID)
        .then((response) => {
          
          if (response.status === 200) this.$emit("removeProduct", productID);
          this["alert/newAlert"]({
            type: "success",
            message: "Product Deleted successfully",
          });
          setTimeout(async () => {
            this.showAlertError = false;
            this["alert/clear"]();
          }, 3000);
        })
        .catch((e) => {
          this["alert/newAlert"]({
            type: "error",
            message: e.data.message,
          });
          setTimeout(async () => {
            this.showAlertError = false;
            this["alert/clear"]();
          }, 3000);

          console.log(e);
        });
    },
    toCurrency: function (price, currency) {
      var formatter = new Intl.NumberFormat("en-EU", {
        style: "currency",
        currency: currency ? currency : "USD",
        //currency: "EUR",
      });
      const curerncy_price = formatter.format(price);

      return curerncy_price;
    },
    backToImportList(productID) {
      const agreed = window.confirm(
        "Are you sure you want to go back to import list?"
      );
      //this.removingProduct = false;
      if (agreed)
        RetailerProducts.backToImportList(productID)
          .then((response) => {
            console.log(response);
            // hcheck the reponse here before doing this next lines
            if (response.status === 200) this.$emit("removeProduct", productID);

            this.backingImportlist = false;
            this["alert/newAlert"]({
              type: "success",
              message: "Product backed to import list successfully",
            });
            setTimeout(async () => {
              this.showAlertError = false;
              this["alert/clear"]();
            }, 3000);
          })
          .catch((e) => {
            this["alert/newAlert"]({
              type: "error",
              message: e.data.message,
            });
            setTimeout(async () => {
              this.showAlertError = false;
              this["alert/clear"]();
            }, 3000);

            console.log(e);
          });
    },
    calculateTotalStock(variants) {
      let totalStock = 0;
      for (const variant of variants) {
        totalStock += variant.stock;
      }
      return totalStock;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables2.scss";

.product-card-table {
  background: rgb(255, 255, 255);

  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
    0px 0px 2px rgba(16, 24, 40, 0.06);
  @include lg {
    overflow-x: auto;
  }
}
.bulk-container {
  position: fixed;
  z-index: 9999999;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 50px;
  width: 450px;
  left: calc(50% - 225px);
  background: #202123;
  height: 70px;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.85) 0px 4px 4px 0px;
  .vgang_btn {
    @include md {
      font-size: 12px;
    }
  }
  @include md {
    height: 60px;
    width: 60vw;
    left: calc(50% - 144px);
    bottom: 70px;
  }
  @include sm {
    width: 70vw;
    left: calc(50% - 130px);
    bottom: 70px;
  }
  @include breakpoint(414px) {
    left: calc(50% - 110px);
  }
}
.tableEdtiBtn {
  position: relative;
  cursor: pointer;
  border: none !important;
  padding: 5px !important;
  &:hover {
    .tooltiptext {
      visibility: visible;
    }
  }
  .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff !important;
    text-decoration: none !important;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 12222222222222222222;
    bottom: 120%;
    left: 50%;
    margin-left: -60px !important;

    &:after {
      content: "";
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: black transparent transparent transparent;
    }
  }
}
.icon-color {
  filter: brightness(0) saturate(100%) invert(56%) sepia(4%) saturate(431%)
    hue-rotate(169deg) brightness(102%) contrast(83%);
}
.text-span {
  font-size: 15px !important;
}

.orderlist-table {
  width: 100%;
  background: white;
  margin: 12px;
  min-width: 960px;
  border-radius: 10px;

  th {
    padding-top: 26px;
    padding-bottom: 14px;
    padding-left: 16px;

    max-width: 314px;
    border-bottom: 1px solid #ededed;
  }

  td {
    padding-top: 12px;

    font-size: 14px;
    padding-left: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: $grey-600;
    width: 700px;
  }

  .price {
    padding-top: 18px;
    padding-bottom: 80px;
  }

  .each-row {
    border-bottom: 1px solid #ededed;

    &:last-child {
      border-bottom: 1px solid transparent;
    }

    .button {
      border: 1px solid $primary;
      color: $primary;
      border-radius: 10px;
      padding: 10px 24px;
      font-size: 12px;
      font-weight: bold;
      text-transform: uppercase;
      cursor: pointer;
    }
  }

  .status {
    font-size: 12px;
    padding: 5px 10px;

    .status-icon {
      vertical-align: text-bottom;
      margin-right: 5px;
      display: inline-block;
      font-size: 18px;
    }

    &.waiting {
      color: white;
      background: #00e676;
      border-radius: 5px;
    }

    &.delivered-to-post {
      color: white;
      background: #0ac2ff;
      border-radius: 5px;
    }

    &.pending {
      color: white;
      background: #ff7e9d;
      border-radius: 5px;
    }

    &.paid {
      color: $primary;
      border-radius: 5px;
      border: 1px solid $primary;
    }
  }
}

.product-title-row {
  display: flex;
  align-items: center;

  .image-container {
    flex: none;
    width: 50px !important;
    height: 50px !important;
    border-radius: 5px;
    overflow: hidden;
    margin-right: 12px;
    box-shadow: 0 20px 50px 10px rgba(29, 41, 54, 0.1);

    img {
      width: 100%;
      height: 100%;
    }
  }

  .product-title {
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: $grey-500;
  }
}

.my-product-page-actions {
  display: flex;
  align-items: stretch;
  justify-content: center;

  .view,
  .delete {
    display: flex;
    align-items: center;
    cursor: pointer;

    span {
      margin-left: 6px;
      font-size: 12px;
      text-decoration: underline;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $primary;
    }
  }

  .delete {
    span {
      color: $red-400;
    }
  }

  .view {
    span {
      padding-right: 12px;
    }
  }
}
</style>
