<template>
  <main class="my-products-section">
    <div>
      <div
        class="import-list-card"
        :style="
          !$route.path.includes('/sh-retailer') ? { 'padding-top': '50px' } : {}
        "
      >
        <retailerNavigation v-if="!$route.path.includes('/sh-retailer')" />

        <v-main
          app
          :class="{
            'retailer-main-container-my-product':
              !$route.path.includes('/sh-retailer'),
          }"
        >
          <div class="page-wrapper mx-3">
            <h1 class="page-header" style="font-weight: 700">My Products</h1>

            <div>
              <div
                class="orderlist-table-container"
                style="padding: 10px; margin-bottom: 20px"
              >
                <div
                  class="query_container justify-space-between"
                  style="margin-bottom: 20px"
                >
                  <SearchBtnV3
                    class="search-input"
                    :placeholder="myProductsPagination.search || ' Search...'"
                    :searchQuery="myProductsPagination.search"
                    @search="Search($event)"
                    @removeText="removeText()"
                  />
                  <SelectDropV2
                    style="background: #fff"
                    :icon="'sort'"
                    :sortList="['Newest', 'Oldest']"
                    @sortby="SortBy($event)"
                    :maxWidth="'170px'"
                  />
                </div>
                <SmartChips
                  class="mb-5"
                  :chips="chips"
                  @removeChips="removeChips"
                />
                <ProductCardListTable
                  style="background: #fff"
                  v-if="hasProduct"
                  :tableHeads="[
                    'Product',
                    'Inventory',
                    'Cost',
                    'Selling price',
                  ]"
                  :products="listOfProducts"
                  @force-render="forceRender()"
                  @removeProduct="
                    listOfProducts = listOfProducts.filter(
                      (product) => product.id !== $event
                    )
                  "
                ></ProductCardListTable>
                <PCard v-else class="empty-products-container">
                  <div
                    class="container row-gap-3 d-flex justify-content-center flex-column align-items-center"
                  >
                    <img
                      class="chair-image"
                      src="@/assets/img/importListBg.png"
                      loading="lazy"
                      alt="import list is empty"
                    />
                    <h4 class="header">Your import list is empty!</h4>
                    <p class="description">
                      Head over to the find product page to start adding
                      products to your import list.
                    </p>
                    <router-link :to="'./list-of-products?host=' + host">
                      <PButton class="Explore-btn" primary
                        >Explore Products to Sell</PButton
                      >
                    </router-link>
                  </div>
                </PCard>
              </div>
              <div class="d-flex mb-6 justify-content-center">
                <v-row style="flex: unset" v-if="page_size > 1">
                  <v-col cols="12" sm="12" md="12">
                    <v-pagination
                      v-model="page"
                      :length="page_size"
                      :total-visible="7"
                      @input="next"
                      color="#008f4a"
                      prev-icon="chevron_left"
                      next-icon="chevron_right"
                    ></v-pagination>
                  </v-col>
                </v-row>
              </div>
            </div>
          </div>
        </v-main>
      </div>
    </div>
  </main>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
// components
 
import retailerNavigation from "@/components/navigation/retailer.vue";
import SelectDropV2 from "@/components/bases/SelectDropV2.vue";
import SearchBtnV3 from "@/components/bases/SearchBtnV3.vue";
import ProductCardListTable from "@/components/retailer/ProductCardListTable.vue";

 
import SmartChips from "@/components/retailer/chips/SmartChips.vue";
import {  isShopifyEmbedded} from '@shopify/app-bridge/utilities';
// Services

import RetailerProducts from "@/services/RetailerProducts";
import { getVariable } from "@/utils/conditionalStoreVariables";
export default {
  components: {
    
    retailerNavigation,
   
    SmartChips,
    SelectDropV2,
    SearchBtnV3,
    ProductCardListTable,
  },
  data() {
    return {
      userType: "retailer",
      listOfProducts: [],
      chips: [],
      count: 12,
      page: Number,
      page_size: 0,
      totalProductCount: "",
      searchQuery: "",
      SearchButtonIsDisabled: true,
      hasProduct: false,
      currentSort: "newest",
      productVarient: [],
      removingProduct: false,
      backingImportlist: false,
      host: null,
    };
  },
  created() {

    if (isShopifyEmbedded()) {  
      const url_string = window.location.href;
      const url = new URL(url_string);
      this.host = url.searchParams.get("host");
    }
  },
  async mounted() {
    
    this.userType = getVariable("role");
    const retailer = JSON.parse(JSON.stringify(this.$store.state.retailer));

    if (
      retailer.myProductsPagination &&
      Object.keys(retailer.myProductsPagination).length === 0 &&
      Object.getPrototypeOf(retailer.myProductsPagination) === Object.prototype
    ) {
      this.page = 1;

      if (this.myProductsPagination.sort) {
        this.generateChips("sort", this.myProductsPagination.sort);
      }
      if (this.myProductsPagination.search) {
        this.generateChips("q", this.myProductsPagination.search);
      }
    } else {
      this.page = retailer.myProductsPagination.offset / this.count + 1;
      if (this.myProductsPagination.sort) {
        this.generateChips("sort", this.myProductsPagination.sort);
      }
      if (this.myProductsPagination.search) {
        this.generateChips("q", this.myProductsPagination.search);
      }
    }

    this.generateChips();
    await this.getMyProducts(this.myProductsPagination);
  },
  filters: {
    toCurrency: function (value) {
      if (typeof value !== "number") {
        return value;
      }
      var formatter = new Intl.NumberFormat("en-EU", {
        style: "currency",
        currency: "EUR",
        minimumFractionDigits: 2,
      });
      return formatter.format(value);
    },
  },
  computed: {
    urlRoute() {
      return { ...this.$route.query };
    },
    ...mapGetters({
      newAlert: "alert/newAlert",
      alertError: "alert/alertError",
      myProductsPagination: "retailer/myProductsPagination",
    }),
  },

  methods: {
    ...mapActions([
      "retailer/setMyProductsPagination",
      "alert/clear",
      "alert/newAlert",
    ]),
    removeText() {
      const found = this.chips?.find((chip) => chip?.type == "q");
      if (found) this.removeChips(found);
    },
    async getMyProducts(oldPag) {
      const filterByCategory =
        this.currentSort === ("newest" || "latest") ? null : this.currentSort;
      let pag = {
        count: this.count,
        offset: this.count * (this.page - 1),
        sort: this.myProductsPagination.sort || "newest",
      };
      if (
        oldPag &&
        Object.keys(oldPag).length === 0 &&
        Object.getPrototypeOf(oldPag) === Object.prototype
      )
        await this["retailer/setMyProductsPagination"](pag);
      else await this["retailer/setMyProductsPagination"](oldPag);

      RetailerProducts.getImportedProducts(this.myProductsPagination)
        .then((response) => {
          let data = response.data.products;

          this.listOfProducts = data;
          this.totalProductCount = response.data.totalCount;
          this.page_size = Math.ceil(response.data.totalCount / this.count);

          this.hasProduct = data?.length ? true : false;
          window.scrollTo(0, 0);
          this.$router
            .push({
              query: {
                sort: this.myProductsPagination.sort || "newest",
                ...(this.myProductsPagination.search && {
                  q: this.myProductsPagination.search,
                }),
              },
            })
            .catch(() => {});
        })
        .catch((e) => {
          this["alert/newAlert"]({
            type: "error",
            message: e.data.message,
          });
          setTimeout(async () => {
            this.showAlertError = false;
            this["alert/clear"]();
          }, 3000);
        });
    },
    async forceRender() {
      this.listOfProducts = [];

      if (this.page == this.page_size) {
        this.next(this.page - 1);
      } else {
        this.next(this.page + 1);
      }
      this.page_size = this.page_size - 1;
    },
    async Search(event) {
      let pag = {
        count: this.count,
        offset: 0,
        search: event.trim(),
        sort: this.myProductsPagination.sort || "newest",
      };
      await this["retailer/setMyProductsPagination"](pag);
      RetailerProducts.getImportedProducts(pag)
        .then((response) => {
          this.listOfProducts = response.data.products;
          this.totalProductCount = response.data.totalCount;
          this.page_size = Math.ceil(response.data.totalCount / this.count);
          this.generateChips("q", event);
          this.$router.push({
            query: {
              sort: this.myProductsPagination.sort || "newest",
              ...(this.myProductsPagination.search && {
                q: this.myProductsPagination.search,
              }),
            },
          });
        })
        .catch((e) => {
          this["alert/newAlert"]({
            type: "error",
            message: e.data.message,
          });
          setTimeout(async () => {
            this.showAlertError = false;
            this["alert/clear"]();
          }, 3000);
        });
    },

    async SortBy(event) {
      console.log(event);
      this.currentSort =
        event.sort_by.charAt(0).toUpperCase() + event.sort_by.slice(1);

      let pag = {
        count: this.count,
        offset: 0,
        sort: event.sort_by.toLowerCase(),
        search: this.myProductsPagination.search || "",
      };
      await this["retailer/setMyProductsPagination"](pag);
      RetailerProducts.getImportedProducts(this.myProductsPagination)
        .then((response) => {
          this.listOfProducts = response.data.products;
          this.totalProductCount = response.data.totalCount;
          this.page_size = Math.ceil(response.data.totalCount / this.count);
          this.hasProduct = this.listOfProducts.length ? true : false;
          window.scrollTo(0, 0);

          this.generateChips("sort", event.sort_by);
          this.$router.push({
            query: {
              sort: this.myProductsPagination.sort || "newest",
              ...(this.myProductsPagination.search && {
                q: this.myProductsPagination.search,
              }),
            },
          });
        })
        .catch((e) => {
          this["alert/newAlert"]({
            type: "error",
            message: e.data.message,
          });
          setTimeout(async () => {
            this.showAlertError = false;
            this["alert/clear"]();
          }, 3000);
        });
    },
    next(page) {
      const newPag = JSON.parse(JSON.stringify(this.myProductsPagination));
      newPag.offset = this.count * (page - 1);
      this["retailer/setMyProductsPagination"](newPag);

      this.getMyProducts(newPag);
    },
    checkSearchButtonStatus() {
      if (this.searchQuery.length > 0) {
        this.SearchButtonIsDisabled = false;
      } else {
        this.SearchButtonIsDisabled = true;
      }
    },
    removeChips(chip) {
      let urlQueries = Object.assign({}, this.$route.query);

      if (chip.type === "q") {
        delete urlQueries[chip.type];
        delete this.myProductsPagination["search"];
        this.removeFromChips(chip.type);
        RetailerProducts.getImportedProducts(this.myProductsPagination)
          .then((response) => {
            this.listOfProducts = response.data.products;
            this.totalProductCount = response.data.totalCount;
            this.page_size = Math.ceil(response.data.totalCount / this.count);
            this.hasProduct = this.listOfProducts.length ? true : false;
          })
          .catch((e) => {
            this["alert/newAlert"]({
              type: "error",
              message: e.data.message,
            });
            setTimeout(async () => {
              this.showAlertError = false;
              this["alert/clear"]();
            }, 3000);
          });
      }

      if (chip.type === "sort") {
        delete urlQueries[chip.type];
        delete this.myProductsPagination["sort"];
        this.removeFromChips(chip.type);
        RetailerProducts.getImportedProducts(this.myProductsPagination)
          .then((response) => {
            this.listOfProducts = response.data.products;
            this.totalProductCount = response.data.totalCount;
            this.page_size = Math.ceil(response.data.totalCount / this.count);
            this.hasProduct = this.listOfProducts.length ? true : false;
          })
          .catch((e) => {
            this["alert/newAlert"]({
              type: "error",
              message: e.data.message,
            });
            setTimeout(async () => {
              this.showAlertError = false;
              this["alert/clear"]();
            }, 3000);
          });
      }
    },
    removeFromChips(value) {
      const index = this.chips.findIndex((obj) => obj["type"] === value);
      if (index !== -1) {
        this.chips.splice(index, 1);
      }
    },
    async generateChips(type, value) {
      let newchips = {};
      if (type === "q") {
        newchips = {
          type: "q",
          value: value,
        };

        const foundedChip = this.chips.find((chip) => chip["type"] === type);
        if (!foundedChip) {
          this.chips.push(newchips);
        } else {
          this.chips.find((chip) => {
            if (chip["type"] === type) {
              chip["value"] = value;
            }
          });
        }
      }

      if (type === "sort") {
        newchips = {
          type: "sort",
          value: value,
        };
        const foundedChip = this.chips.find((chip) => chip["type"] === type);

        if (!foundedChip) {
          this.chips.push(newchips);
        } else {
          this.chips.find((chip) => {
            if (chip["type"] === type) {
              chip["value"] = value;
            }
          });
        }
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/variables2.scss";
.my-products-section {
  .query_container {
    @include md {
      row-gap: 8px;
    }
  }
  .search-input {
    width: 50% !important;
    @include md {
      width: 100% !important;
    }
  }
  .chips {
    flex-wrap: wrap;
    row-gap: 10px;
  }
  .chips__item {
    align-items: center;
    padding: 5px;
  }
  .chips__item__text,
  .chips__item__icon {
    font-size: 14px;
    color: #202223;
  }
  .page-header {
    color: #202223;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 10px;
  }
  .empty-products-container {
    padding: 89px;
    margin-top: 21px;
    @include md {
      padding: 34px;
    }
    @include sm {
      padding: 21px;
    }
    .chair-image {
      height: 172px;
      width: 148px;
    }
    .Explore-btn {
      font-size: 12px;
    }
    .header {
      color: #202223;
      text-align: center;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      border-bottom: unset !important;
      @include sm {
        font-size: 16px;
      }
    }
    .description {
      color: #6d7175;
      text-align: center;

      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }
}

.no-access {
  border-radius: 10px;
  text-align: center;
  color: #ff0a43;
  padding: 30px;
  border: 1px solid #ff0a43;
}

.importlist_banner {
  margin-top: 16px;

  width: 100%;
}

.retailer-main-container-my-product {
  @media screen and (min-width: 1621px) {
    width: calc(100% - 263px) !important;
    margin-left: auto;
  }

  @media screen and (min-width: 300px) {
    width: calc(100% - 75px);
    margin-left: auto;
  }
}

.empty-space {
  position: relative;
  padding-left: 0 !important;

  ._title {
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #435160;
    margin-top: 60px;
    margin-bottom: 30px;
  }

  ._desc {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #435160;
    margin-bottom: 30px;
  }

  ._bg {
    position: absolute;
    top: 100px;
    right: 100px;
  }
}

.fake_select_wrapper {
  width: 257px !important;
}

.stock_alert {
  background: $yellow-400;
  color: white;
  font-size: $font_size_base + 0.2rem;
  font-weight: bold;
  padding: 4px 8px;
  border-radius: 8px;
}

.stock_low {
  background: $red-500;
  color: white;
  font-size: $font_size_base + 0.2rem;
  font-weight: bold;
  padding: 4px 8px;
  border-radius: 8px;
}

.import_list_card_disabled {
  &:after {
    position: absolute;
    content: "";
    display: block;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.5);
    cursor: not-allowed;
  }
}

@media screen and (max-width: 1024px) {
  .orderlist-table-container {
    overflow-x: scroll;
  }
}
</style>
