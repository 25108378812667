var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',{staticClass:"my-products-section"},[_c('div',[_c('div',{staticClass:"import-list-card",style:(!_vm.$route.path.includes('/sh-retailer') ? { 'padding-top': '50px' } : {})},[(!_vm.$route.path.includes('/sh-retailer'))?_c('retailerNavigation'):_vm._e(),_c('v-main',{class:{
          'retailer-main-container-my-product':
            !_vm.$route.path.includes('/sh-retailer'),
        },attrs:{"app":""}},[_c('div',{staticClass:"page-wrapper mx-3"},[_c('h1',{staticClass:"page-header",staticStyle:{"font-weight":"700"}},[_vm._v("My Products")]),_c('div',[_c('div',{staticClass:"orderlist-table-container",staticStyle:{"padding":"10px","margin-bottom":"20px"}},[_c('div',{staticClass:"query_container justify-space-between",staticStyle:{"margin-bottom":"20px"}},[_c('SearchBtnV3',{staticClass:"search-input",attrs:{"placeholder":_vm.myProductsPagination.search || ' Search...',"searchQuery":_vm.myProductsPagination.search},on:{"search":function($event){return _vm.Search($event)},"removeText":function($event){return _vm.removeText()}}}),_c('SelectDropV2',{staticStyle:{"background":"#fff"},attrs:{"icon":'sort',"sortList":['Newest', 'Oldest'],"maxWidth":'170px'},on:{"sortby":function($event){return _vm.SortBy($event)}}})],1),_c('SmartChips',{staticClass:"mb-5",attrs:{"chips":_vm.chips},on:{"removeChips":_vm.removeChips}}),(_vm.hasProduct)?_c('ProductCardListTable',{staticStyle:{"background":"#fff"},attrs:{"tableHeads":[
                  'Product',
                  'Inventory',
                  'Cost',
                  'Selling price',
                ],"products":_vm.listOfProducts},on:{"force-render":function($event){return _vm.forceRender()},"removeProduct":function($event){_vm.listOfProducts = _vm.listOfProducts.filter(
                    (product) => product.id !== $event
                  )}}}):_c('PCard',{staticClass:"empty-products-container"},[_c('div',{staticClass:"container row-gap-3 d-flex justify-content-center flex-column align-items-center"},[_c('img',{staticClass:"chair-image",attrs:{"src":require("@/assets/img/importListBg.png"),"loading":"lazy","alt":"import list is empty"}}),_c('h4',{staticClass:"header"},[_vm._v("Your import list is empty!")]),_c('p',{staticClass:"description"},[_vm._v(" Head over to the find product page to start adding products to your import list. ")]),_c('router-link',{attrs:{"to":'./list-of-products?host=' + _vm.host}},[_c('PButton',{staticClass:"Explore-btn",attrs:{"primary":""}},[_vm._v("Explore Products to Sell")])],1)],1)])],1),_c('div',{staticClass:"d-flex mb-6 justify-content-center"},[(_vm.page_size > 1)?_c('v-row',{staticStyle:{"flex":"unset"}},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-pagination',{attrs:{"length":_vm.page_size,"total-visible":7,"color":"#008f4a","prev-icon":"chevron_left","next-icon":"chevron_right"},on:{"input":_vm.next},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1):_vm._e()],1)])])])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }